import { AfterViewInit, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '../../../../../core/auth/selectors/auth.selectors';
import { UserDataApiActions } from '../../../../../core/users/actions/user-data-api.actions';
import {
  UserDataSelectors
} from '../../../../../core/users/selectors/user-data.selectors';
import {
  SYMPTOMS_QUESTION_GROUPS
} from '../../../../../shared/components/charts/symptons-yes-no-bar-chart/definitions/symptoms-question-groups';
import { User, UserListItem } from '../../../../../shared/models';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-monitored-user-analysis',
  templateUrl: './monitored-user-analysis.component.html',
  styleUrls: ['./monitored-user-analysis.component.scss'],
})
export class MonitoredUserAnalysisComponent implements AfterViewInit {
  private loggedInUser$ = this.store.select(AuthSelectors.getLoggedInUser);

  @Input() public monitoredUser: UserListItem;
  public now = new Date();
  public analysisDate = this.getFormattedDate(this.now);
  public selectedDate = this.now;

  public weightData$ = this.store.select(UserDataSelectors.getWeightHistoryEntries);
  public symptomsRedData$ = this.store.select(
    UserDataSelectors.getSymptomsHistoryData(SYMPTOMS_QUESTION_GROUPS.RED_PRO_CTCAE_GROUP)
  );

  public symptomsYellowData$ = this.store.select(
    UserDataSelectors.getSymptomsHistoryData(SYMPTOMS_QUESTION_GROUPS.YELLOW_PRO_CTCAE_GROUP)
  );
  public generalHealthData$ = this.store.select(UserDataSelectors.getGeneralHealthHistoryEntries);

  constructor(private store: Store) {}

  ngAfterViewInit(): void {
    this.fetchUserData();
  }

  private getFormattedDate(date: Date): string {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const formatter = new Intl.DateTimeFormat('de-DE', { month: 'long', year: 'numeric' });
    const formattedDate = formatter.format(firstDayOfMonth);
    return formattedDate.replace(' ', ' / ');
  }

  public readonly datepickerConfig: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false,
    dateInputFormat: 'MM.YYYY',
    useUtc: true,
    minMode: 'month',
  };

  public onDateChange(date: Date): void {
    this.selectedDate = date;
    this.analysisDate = this.getFormattedDate(date);
    this.fetchUserData();
  }

  private fetchUserData() {
    const selectedMonth = this.selectedDate.getMonth() + 1;
    const selectedYear = this.selectedDate.getFullYear();

    this.loggedInUser$
      .pipe(
        take(1),
        tap((loggedInUser: User) => {
          this.store.dispatch(
            UserDataApiActions.getUserGeneralHealthHistoryData({
              monitoredUserId: this.monitoredUser.id,
              userId: loggedInUser.id,
              startDate: new Date(selectedYear, selectedMonth - 1, 1),
              endDate: new Date(selectedYear, selectedMonth, 1),
            })
          );

          this.store.dispatch(
            UserDataApiActions.getUserWeightHistoryData({
              monitoredUserId: this.monitoredUser.id,
              userId: loggedInUser.id,
              startDate: new Date(selectedYear, selectedMonth - 1, 1),
              endDate: new Date(selectedYear, selectedMonth, 1),
            })
          );
          this.store.dispatch(
            UserDataApiActions.getUserSymptomsHistoryData({
              monitoredUserId: this.monitoredUser.id,
              userId: loggedInUser.id,
              startDate: new Date(selectedYear, selectedMonth - 1, 1),
              endDate: new Date(selectedYear, selectedMonth, 1),
              questionGroup: SYMPTOMS_QUESTION_GROUPS.RED_PRO_CTCAE_GROUP
            })
          );
          this.store.dispatch(
            UserDataApiActions.getUserSymptomsHistoryData({
              monitoredUserId: this.monitoredUser.id,
              userId: loggedInUser.id,
              startDate: new Date(selectedYear, selectedMonth - 1, 1),
              endDate: new Date(selectedYear, selectedMonth, 1),
              questionGroup: SYMPTOMS_QUESTION_GROUPS.YELLOW_PRO_CTCAE_GROUP
            })
          );
        })
      )
      .subscribe();
  }
}
