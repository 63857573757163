import { Action, createReducer, on } from '@ngrx/store';
import { ValidationError } from '../../../shared/models';

import * as AsyncStateActions from '../actions/async-state.actions';
import { AsyncData } from '../models/async-action.model';
import { getErrorState } from '../utils/async-state-action.utils';

export interface AsyncErrorState {
  httpStatus?: number;
  errorTitle?: string;
  errorText?: string;
  validationErrors?: ValidationError[];
  args?: unknown;
}

export interface AsyncProcessState {
  pending: boolean;
  error: AsyncErrorState | undefined;
  initialAction?: Action;
}

export interface AsyncState {
  [asyncKey: string]: AsyncProcessState;
}

const initialState: AsyncState = {};

export const asyncStateReducer = createReducer(
  initialState,
  on(
    AsyncStateActions.setAsyncStateRetry,
    AsyncStateActions.setAsyncStateStart,
    (state: AsyncState, { key, initialAction }): AsyncState =>
      startProcessForKey(state, key, initialAction)
  ),
  on(
    AsyncStateActions.setAsyncStateReset,
    AsyncStateActions.setAsyncStateSuccess,
    (state: AsyncState, { key }): AsyncState => finishProcessForKey(state, key)
  ),
  on(
    AsyncStateActions.setAsyncStateFail,
    (state: AsyncState, { asyncErrorData }): AsyncState => failProcessForKey(state, asyncErrorData)
  )
);

export function reducer(state: AsyncState = initialState, action: Action): AsyncState {
  return asyncStateReducer(state, action);
}

function startProcessForKey(state: AsyncState, key: string, initialAction: Action): AsyncState {
  let processState: AsyncProcessState | undefined = state[key] || {};

  processState = {
    ...processState,
    pending: true,
    error: undefined,
    initialAction: initialAction,
  };

  const newState = { ...state };
  newState[key] = processState;

  return newState;
}

function finishProcessForKey(state: AsyncState, key: string): AsyncState {
  const newState = { ...state };
  delete newState[key];

  return newState;
}

function failProcessForKey(state: AsyncState, asyncData: AsyncData): AsyncState {
  let processState: AsyncProcessState | undefined = state[asyncData.asyncKey] || {};

  processState = {
    ...processState,
    pending: false,
    error: getErrorState(asyncData),
  };

  const newState = { ...state };
  newState[asyncData.asyncKey] = processState;

  return newState;
}
