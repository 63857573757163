import { props } from '@ngrx/store';
import { createAsyncAction } from '../../../core/async-state/utils/async-state-action.utils';
import { UpdateUserRequest } from '../../../shared/models/account/requests/update-user-request.model';
import { UpdateUserResponse } from '../../../shared/models/responses/update-user-response.module';

export enum UserUpdateApiActionTypes {
  UpdateUser = '[UserUpdate] Update User',
  UpdateUserSuccess = '[UserUpdate] Update User Success',
  UpdateUserFail = '[UserUpdate] Update User Fail',
}

export const updateUser = createAsyncAction(
  UserUpdateApiActionTypes.UpdateUser,
  {
    asyncKey: UserUpdateApiActionTypes.UpdateUser,
    asyncState: 'start',
  },
  props<{ payload: UpdateUserRequest; userId: string }>()
);

export const updateUserSuccess = createAsyncAction(
  UserUpdateApiActionTypes.UpdateUserSuccess,
  {
    asyncKey: UserUpdateApiActionTypes.UpdateUser,
    asyncState: 'success',
  },
  props<{ response: UpdateUserResponse }>()
);

export const updateUserFail = createAsyncAction(UserUpdateApiActionTypes.UpdateUserFail, {
  asyncKey: UserUpdateApiActionTypes.UpdateUser,
  asyncState: 'fail',
});
