import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  BaseRouterStoreState,
  routerCancelAction,
  routerErrorAction,
  RouterNavigationAction,
  routerNavigationAction,
} from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../core/store/reducers';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class RouterEffects {
  private previousRouterNavigationAction: RouterNavigationAction<BaseRouterStoreState>;
  private currentRouterNavigationAction: RouterNavigationAction<BaseRouterStoreState>;

  saveCurrentNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigationAction),
        tap((action) => {
          this.previousRouterNavigationAction = this.currentRouterNavigationAction;
          this.currentRouterNavigationAction = { ...action };
        })
      ),
    { dispatch: false }
  );

  restoreAfterCancellationOrError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerCancelAction, routerErrorAction),
        filter((action) => Boolean(action)),
        tap(() => {
          if (this.previousRouterNavigationAction) {
            this.store.dispatch(this.previousRouterNavigationAction);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, public store: Store<BaseAppState>) {}
}
