import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from '../../../environments/environment';

import { SELECTED_USER_DATA_STATE_KEY, USERS_STATE_KEY } from './definitions/users.definitions';
import { UserDataEffects } from './effects/user-data.effects';
import { UserSubmissionEffects } from './effects/user-submissions.effects';
import { UserUpdateEffects } from './effects/user-update.effect';
import { UserEffects } from './effects/user.effects';
import { userDataReducers } from './reducers';
import { usersReducer } from './reducers/users.reducers';
import { UserApiService } from './services/user-api.service';
import { UserDataApiService } from './services/user-data-api.service';
import { UserDataMockApiService } from './services/user-data-mock-api.service';
import { UserMockApiService } from './services/user-mock-api.service';
import { UserSubmissionsApiService } from './services/user-submissions.services';

@NgModule({
  imports: [
    StoreModule.forFeature(SELECTED_USER_DATA_STATE_KEY, userDataReducers),
    StoreModule.forFeature(USERS_STATE_KEY, usersReducer),
    EffectsModule.forFeature([
      UserDataEffects,
      UserEffects,
      UserSubmissionEffects,
      UserUpdateEffects,
    ]),
  ],
  providers: [
    {
      provide: UserDataApiService,
      useClass: environment.useMockServices ? UserDataMockApiService : UserDataApiService,
    },
    {
      provide: UserSubmissionsApiService,
      useClass: UserSubmissionsApiService,
    },
    {
      provide: UserApiService,
      useClass: environment.useMockServices ? UserMockApiService : UserApiService,
    },
  ],
})
export class UsersModule {}
