import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { BaseModalComponent } from '../../../../core/modals/components/base-modal/base-modal.component';
import { UserInvitation } from '../../../../shared/models/users/monitored-invitation.model';
import { Store } from '@ngrx/store';
import { UserApiActions } from '../../../../core/users/actions/user-api.actions';

@Component({
  selector: 'app-invite-monitored-user',
  templateUrl: './confirm-user-invitation-modal.component.html',
  styleUrls: ['./confirm-user-invitation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmUserInvitationModalComponent extends BaseModalComponent<UserInvitation> {
  asyncProcessKeys: string[] = [UserApiActions.declineMonitoredUserInvitation.type, UserApiActions.confirmMonitoredUserInvitation.type];

  constructor(protected store: Store, protected readonly injector: Injector) {
    super(injector);
  }

  handleConfirmUserInvitation() {
    this.store.dispatch(UserApiActions.confirmMonitoredUserInvitation({ monitoredUserId: this.data!.id }))
  }

  handleDeclineUserInvitation() {
    this.store.dispatch(UserApiActions.declineMonitoredUserInvitation({ monitoredUserId: this.data!.id }))
  }
}
