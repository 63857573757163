import { Component, Input } from '@angular/core';
import { GeneralHealthHistoryData, GeneralHealthSubject } from '../../../../shared/models';
import { fromInput } from '../../../../shared/utilities/observable-utils';
import { groupBy, snakeCase } from 'lodash';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

import { LineChartData } from '../monthly-multiline-chart/monthly-multiline-chart.component';

@Component({
  selector: 'app-general-health-chart',
  templateUrl: './general-health-chart.component.html',
  styleUrls: ['./general-health-chart.component.scss'],
})
export class GeneralHealthChartComponent {
  @Input('data') public rawData?: GeneralHealthHistoryData[] | null;
  @Input() public date?: Date | null;

  public readonly colorMap = {
    [GeneralHealthSubject.FeelingBad]: '#2d88c4',
    [GeneralHealthSubject.FeelingDizzy]: '#2dc4a3',
    [GeneralHealthSubject.FeelingSick]: '#4b2dc4',
    [GeneralHealthSubject.FeelingTired]: '#8d2dc4',
    [GeneralHealthSubject.HavingFear]: '#c42dad',
    [GeneralHealthSubject.HavingLackOfAppetite]: '#c42d4b',
    [GeneralHealthSubject.HavingPain]: '#c46e2d',
    [GeneralHealthSubject.HavingShortnessOfBreath]: '#c4ab2d',
    [GeneralHealthSubject.InBadMood]: '#97c42d',
  };

  public rawData$ = fromInput<GeneralHealthChartComponent>(this)('rawData');
  public chartData$ = this.rawData$.pipe(
    map((data) => data ?? []),
    map((data) => groupBy(data, (item) => item.subject)),
    map((data) => {
      const subjects = Object.keys(data);
      const chartData: LineChartData[] = [];
      for (const subject of subjects) {
        chartData.push({
          color: this.colorMap[subject],
          label: `general.general_health_subjects.${snakeCase(subject)}`,
          values: data[subject].map((item) => ({
            x: moment(item.timestamp).valueOf(),
            y: item.value,
          })),
        });
      }
      return chartData;
    })
  );
}
