import { Injectable } from '@angular/core';
import { ApiResponse, UserListItem } from '../../../shared/models';
import { RemoveMonitoredUsersRequest } from '../../../shared/models/users/requests/remove-monitored-user-request.model';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class UserMockApiService {
  public getMonitoredUsers(_userId: string) {
    const res: ApiResponse<UserListItem[]> = {
      data: [],
      success: true,
      validationErrors: [],
    };

    return of(res).pipe(delay(1000));
  }

  public removeMonitoredUsers(_request: RemoveMonitoredUsersRequest): Observable<ApiResponse<{}>> {
    const res: ApiResponse<{}> = {
      data: {},
      success: true,
      validationErrors: [],
    };

    return of(res).pipe(delay(1000));
  }
}
