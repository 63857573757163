import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as AuthUINavigationActions from '../../../core/auth/actions/auth-ui-navigation.actions';
import { AUTH_UI_PATHS } from '../../../core/auth/definitions/auth-ui-navigation.definitions';
import { BaseAppState } from '../../../core/store/reducers';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthUINavigationEffects {
  goToLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUINavigationActions.goToLogin),
        tap(() => this.router.navigate([AUTH_UI_PATHS.root.login]))
      ),
    { dispatch: false }
  );

  goToRequestPasswordReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUINavigationActions.goToRequestPasswordReset),
        tap(() => this.router.navigate([AUTH_UI_PATHS.root.passwordReset.request]))
      ),
    { dispatch: false }
  );

  goToTermsOfService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUINavigationActions.goToTermsOfService),
        tap(() => this.router.navigate([AUTH_UI_PATHS.root.termsOfService]))
      ),
    { dispatch: false }
  );

  goToEditPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUINavigationActions.goToEditPassword),
        tap(() => this.router.navigate([AUTH_UI_PATHS.root.editPassword]))
      ),
    { dispatch: false }
  );

  goToSignUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUINavigationActions.goToSignUp),
        tap(() => this.router.navigate([AUTH_UI_PATHS.root.signUp]))
      ),
    { dispatch: false }
  );


  goToSignUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthUINavigationActions.goToSignUpSuccess),
        tap(() => this.router.navigate([AUTH_UI_PATHS.root.signUpSuccess]))
      ),
    { dispatch: false }
  );


  constructor(
    private actions$: Actions,
    public store: Store<BaseAppState>,
    private router: Router
  ) {}
}
