import {
  SYMPTOMS_QUESTION_GROUPS
} from '../../../../shared/components/charts/symptons-yes-no-bar-chart/definitions/symptoms-question-groups';

export enum SymptomType {
  DryMouth = 'dry-mouth',
  Flatulence = 'flatulence',
  Diarrhea = 'diarrhea',
  LackOfAppetite = 'lack-of-appetite',
  SoresInMouth = 'sores-in-mouth',
  Exhaustion = 'exhaustion',
  Constipation = 'constipation',
  Nausea = 'nausea',
  AbdominalPain = 'abdominal-pain',
  ChangesInTaste = 'changes-in-taste',
}

export type SymptomHistoryQuestionGroupsData = {
  questionGroup: SYMPTOMS_QUESTION_GROUPS;
  symptomHistoryData: SymptomHistoryData[];
}

export interface SymptomHistoryData {
  timestamp: string;
  symptom: SymptomType;
  value: number;
}
