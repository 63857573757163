import { createReducer, on } from '@ngrx/store';
import { User } from '../../../shared/models';
import { MonitoringMetadata } from '../../../shared/models/account/monitoring-data.model';

import { AuthActions } from '../actions/auth.actions';
import { signUpSuccess } from '../../../core/auth/actions/auth-sign-up.actions';

export interface AuthState {
  status: AuthStatus;
  language: string;
  signUpEmail: string | undefined,
}

export interface AuthStatus {
  token: string | undefined;
  user: User | undefined;
}

export const initialState: AuthState = {
  signUpEmail: 'undefined',
  status: {
    token: undefined,
    user: undefined,
  },
  language: 'de',
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, (state: AuthState, { loginResponse }) => ({
    ...state,
    status: {
      ...state.status,
      token: loginResponse.accessToken,
      user: loginResponse.user,
    },
  })),
  on(AuthActions.renewCodeSuccess, (state: AuthState, { code }) => ({
    ...state,
    status: {
      ...state.status,
      user: handleUpdateCode(state, code),
    },
  })),
  on(signUpSuccess, (state: AuthState, { email }) => ({
    ...state,
    signUpEmail: email,
  })),
  on(AuthActions.confirmTermsOfServiceSuccess, (state: AuthState) => ({
    ...state,
    status: {
      ...state.status,
      user: handleConfirmTermsOfService(state),
    },
  })),
  on(AuthActions.updateUserLanguageSuccess, (state: AuthState, { language }) => ({
    ...state,
    status: {
      ...state.status,
      user: {
        ...state.status.user!,
        language: language,
      },
    },
  })),
  on(AuthActions.setUserLanguage, (state: AuthState, { language }) => ({
    ...state,
    language: language || 'de',
  })),
  on(AuthActions.logout, AuthActions.sessionExpired, () => initialState)
);

export function handleUpdateCode(state: AuthState, code: string): User | undefined {
  const newUser: User = {
    ...(state.status.user as User),
    monitoringMetadata: {
      ...(state.status.user?.monitoringMetadata as MonitoringMetadata),
      code: code,
    },
  };

  return newUser;
}

export function handleConfirmTermsOfService(state: AuthState): User | undefined {
  const newUser: User = {
    ...(state.status.user as User),
    monitoringMetadata: {
      ...(state.status.user?.monitoringMetadata as MonitoringMetadata),
      confirmedTos: true,
    },
  };

  return newUser;
}
