import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AuthSelectors } from '../../../../core/auth/selectors/auth.selectors';
import { NavbarActions } from '../../../../core/nav-bar/actions/nav-bar.actions';
import { NavbarSelectors } from '../../../../core/nav-bar/selectors/nav-bar.selectors';
import { MAIN_NAVIGATION_PATH_ELEMENTS } from '../../../../core/router/definitions/main-navigation.definitions';
import { RouterSelectors } from '../../../../core/router/selectors/router.selectors';
import { BaseAppState } from '../../../../core/store/reducers';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  public isLoggedIn$ = this.store.pipe(select(AuthSelectors.isLoggedIn));
  public isMenuOpen$ = this.store.select(NavbarSelectors.getIsHamburgerMenuOpen);
  public hideLogo$ = this.store
    .select(RouterSelectors.getLastUrlSegment)
    .pipe(
      map(
        (segment) =>
          segment === MAIN_NAVIGATION_PATH_ELEMENTS.privacyPolicy ||
          segment === MAIN_NAVIGATION_PATH_ELEMENTS.legalNotice
      )
    );

  constructor(private store: Store<BaseAppState>) {}

  public handleToggleHamburgerMenu() {
    this.store.dispatch(NavbarActions.toggleHamburgerMenu());
  }
}
