import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AsyncAction } from '../../../core/async-state/models/async-action.model';
import { handleErrors } from '../../../core/async-state/operators/handle-errors';
import * as AuthUINavigationActions from '../../../core/auth/actions/auth-ui-navigation.actions';
import { ModalsActions } from '../../../core/modals/actions/modals.actions';
import { RouterSelectors } from '../../../core/router/selectors/router.selectors';
import { BaseAppState } from '../../../core/store/reducers';
import { ToastService } from '../../../core/toasts/services/toast.service';
import {
  ApiResponse,
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  RenewCodeResponse,
  User,
} from '../../../shared/models';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthActions } from '../actions/auth.actions';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

const RENEWED_CODE_TITLE = 'Code geändert';
const RENEWED_CODE_MESSAGE = 'Ihr Code wurde geändert.';

@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.login),
        map((action) => action.payload),
        exhaustMap((auth: LoginRequest) =>
          this.apiService.login(auth.email, auth.password).pipe(
            handleErrors(() => AuthActions.loginFail()),
            // Success action gets the response data here while in other cases it gets the whole API Response
            map((response: ApiResponse<LoginResponse>) =>
              AuthActions.loginSuccess({ loginResponse: response.data })
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(({ loginResponse }) => {
          const userLanguage = loginResponse.user.language;
          if (userLanguage) {
            this.translateService.use(userLanguage);
            this.store.dispatch(AuthActions.setUserLanguage({ language: userLanguage }));
          }
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        switchMap((action: { token: string } & AsyncAction) => {
          const request: LogoutRequest = {
            accessToken: action.token,
          };

          return this.apiService.logout(request).pipe(
            handleErrors(() => AuthActions.logoutFail()),
            map(() => AuthActions.logoutSuccess()),
            catchError((errorAction: AsyncAction) => of(errorAction))
          );
        })
      ),
    { dispatch: true }
  );

  redirectAfterLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        withLatestFrom(this.store),
        tap(([_action, storeState]: [object & AsyncAction, BaseAppState]) => {
          const queryParams = RouterSelectors.getQueryParams(storeState);
          this.router.navigate([queryParams.returnUrl || '']);
        })
      ),
    { dispatch: false }
  );

  redirectToLoginAfterSessionExpired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.sessionExpired),
        map(() => AuthUINavigationActions.goToLogin())
      ),
    { dispatch: true }
  );

  redirectToLoginAfterLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        map(() => AuthUINavigationActions.goToLogin())
      ),
    { dispatch: true }
  );

  showMessageAfterSuccessfulLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutSuccess),
        tap(() =>
          this.toastService.showSuccess(
            this.translateService.instant('toast.logout_success.title'),
            this.translateService.instant('toast.logout_success.message')
          )
        )
      ),
    { dispatch: false }
  );

  showMessageAfterSessionExpired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.sessionExpired),
        tap(() =>
          this.toastService.showInfo(
            this.translateService.instant('toast.session_expired.title'),
            this.translateService.instant('toast.session_expired.message')
          )
        )
      ),
    { dispatch: false }
  );

  renewCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.renewCode),
        switchMap(() =>
          this.apiService.renewCode().pipe(
            handleErrors(() => AuthActions.renewCodeFail()),
            map((response: ApiResponse<RenewCodeResponse>) =>
              AuthActions.renewCodeSuccess({ code: response.data.code })
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  showSuccessMessageAfterRenewCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.renewCodeSuccess),
        tap(() => this.toastService.showInfo(RENEWED_CODE_TITLE, RENEWED_CODE_MESSAGE))
      ),
    { dispatch: false }
  );

  confirmTermsOfService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.confirmTermsOfService),
        switchMap(() =>
          this.apiService.confirmTermsOfService().pipe(
            handleErrors(() => AuthActions.confirmTermsOfServiceUserFail()),
            map(() => AuthActions.confirmTermsOfServiceSuccess()),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  closeModalOnConfirmSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.confirmTermsOfServiceSuccess),
        map(() => ModalsActions.closeDisclaimerModal())
      ),
    { dispatch: true }
  );

  updateUserLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.updateUserLanguage),
        switchMap((action) =>
          this.apiService.updateUserLanguage(action.language).pipe(
            handleErrors(() => AuthActions.updateUserLanguageFail()),
            map((response: ApiResponse<{ user: User }>) =>
              AuthActions.updateUserLanguageSuccess({
                language: response.data.user.language!,
              })
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private store: Store<BaseAppState>,
    private router: Router,
    private apiService: AuthService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}
}
