import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BaseModalComponent } from '../../../../../core/modals/components/base-modal/base-modal.component';
import {
  ChangeMonitoredUserFlowRequest
} from '../../../../../shared/models/users/requests/change-monitored-user-flow-request.model';
import { emailPatternValidator } from '../../../../../shared/form-validators/email-pattern-validator';
import { UserApiActions } from '../../../../../core/users/actions/user-api.actions';

@Component({
  selector: 'app-invite-monitored-user',
  templateUrl: './invite-monitored-user.component.html',
  styleUrls: ['./invite-monitored-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteMonitoredUserComponent extends BaseModalComponent<ChangeMonitoredUserFlowRequest> {
  emailAddress = new FormControl('', [Validators.required, emailPatternValidator]);
  asyncProcessKeys = [UserApiActions.submitMonitoredUserInvitation.type];
  handleSubmitInvitation() {
    if (this.emailAddress.value) {
      this.store.dispatch(UserApiActions.submitMonitoredUserInvitation({ email: this.emailAddress.value }));
    }
  }
}
