import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AsyncAction } from '../../../core/async-state/models/async-action.model';
import { handleErrors } from '../../../core/async-state/operators/handle-errors';
import {
  ApiResponse,
  GeneralHealthHistoryData,
  SymptomHistoryData,
  WeightHistoryData,
} from '../../../shared/models';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { UserDataApiActions } from '../actions/user-data-api.actions';
import { UserDataApiService } from '../services/user-data-api.service';

@Injectable()
export class UserDataEffects {
  getUserWeightHistoryData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserDataApiActions.getUserWeightHistoryData),
        switchMap((action) =>
          this.apiService
            .getUserWeightHistoryData(
              action.userId,
              action.monitoredUserId,
              action.startDate,
              action.endDate
            )
            .pipe(
              handleErrors(() => UserDataApiActions.getUserWeightHistoryDataFail()),
              map((res: ApiResponse<WeightHistoryData[]>) =>
                UserDataApiActions.getUserWeightHistoryDataSuccess({ payload: res.data })
              ),
              catchError((errAction: AsyncAction) => of(errAction))
            )
        )
      ),
    { dispatch: true }
  );

  getUserSymptomsHistoryData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserDataApiActions.getUserSymptomsHistoryData),
        mergeMap((action) =>
          this.apiService
            .getSymptomsHistoryData(
              action.userId,
              action.monitoredUserId,
              action.startDate,
              action.endDate,
              action.submissionId,
              action.questionGroup
            )
            .pipe(
              handleErrors(() => UserDataApiActions.getUserSymptomsHistoryDataFail()),
              map((res: ApiResponse<SymptomHistoryData[]>) =>
                UserDataApiActions.getUserSymptomsHistoryDataSuccess({
                  questionGroup: action.questionGroup,
                  payload: res.data,
                })
              ),
              catchError((errAction: AsyncAction) => of(errAction))
            )
        )
      ),
    { dispatch: true }
  );

  getUserGeneralHealthHistoryData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserDataApiActions.getUserGeneralHealthHistoryData),
        switchMap((action) =>
          this.apiService
            .getGeneralHealthHistoryData(
              action.userId,
              action.monitoredUserId,
              action.startDate,
              action.endDate
            )
            .pipe(
              handleErrors(() => UserDataApiActions.getUserGeneralHealthHistoryDataFail()),
              map((res: ApiResponse<GeneralHealthHistoryData[]>) =>
                UserDataApiActions.getUserGeneralHealthHistoryDataSuccess({ payload: res.data })
              ),
              catchError((errAction: AsyncAction) => of(errAction))
            )
        )
      ),
    { dispatch: true }
  );

  constructor(private actions$: Actions, private apiService: UserDataApiService) {}
}
