import { ActionReducer, Action } from '@ngrx/store';
import { AuthActions } from '../../../core/auth/actions/auth.actions';

import { BaseAppState } from './index';

export function logoutResetReducer(reducer: ActionReducer<BaseAppState>) {
  return function (state: BaseAppState, action: Action) {
    const isLogout =
      action.type === AuthActions.AuthActionTypes.Logout ||
      action.type === AuthActions.AuthActionTypes.SessionExpired;

    const nextState = isLogout ? undefined : state;
    return reducer(nextState, action);
  };
}
