import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalsActions } from '../../../../../core/modals/actions/modals.actions';
import { BaseAppState } from '../../../../../core/store/reducers';
import { User, UserFlow } from '../../../../../shared/models';
import { ChangeMonitoredUserFlowRequest } from '../../../../../shared/models/users/requests/change-monitored-user-flow-request.model';

@Component({
  selector: 'app-monitored-user-flow',
  templateUrl: './monitored-user-flow.component.html',
  styleUrls: ['./monitored-user-flow.component.scss'],
})
export class MonitoredUserFlowComponent implements OnInit {
  @Input() public monitoredUser: User;

  public selectedFlow: UserFlow;
  public UserFlow = UserFlow;
  public selectedFlowDescriptions = {
    [UserFlow.Red]: 'general.flow_information.red_description',
    [UserFlow.Yellow]: 'general.flow_information.yellow_description',
    [UserFlow.Green]: [
      'general.flow_information.green_description',
      'general.flow_information.green_description2',
    ],
  };
  currentIcons = {
    [UserFlow.Red]: 'assets/images/red-flow-icon.svg',
    [UserFlow.Yellow]: 'assets/images/yellow-flow-icon.svg',
    [UserFlow.Green]: 'assets/images/green-flow-icon.svg',
  };
  whiteFlowIcon = 'assets/images/white-flow-icon.svg';

  constructor(private store: Store<BaseAppState>) {}

  ngOnInit(): void {
    if (this.monitoredUser.flow) {
      this.selectedFlow = this.monitoredUser.flow;
    }
  }

  public handleChangeFlow(userId: string) {
    const request: ChangeMonitoredUserFlowRequest = {
      monitoredUserId: userId,
      selectedFlow: this.selectedFlow,
    };

    this.store.dispatch(ModalsActions.openConfirmChangeFlowModal({ request }));
  }

  public updateSelectedFlow(userFlow: UserFlow) {
    this.selectedFlow = userFlow;
  }

  public changeIcon(iconSrc: string, userFlow: UserFlow) {
    this.currentIcons[userFlow] = iconSrc;
  }
}
