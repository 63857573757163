<div class="bg-dark">
  <div class="mx-1">
    <div class="py-5">
      <div class="row d-flex justify-content-center">
        <div class="col-md-4 gx-5 my-auto left-padding">
          <p>
            <img
              src="../../../../assets/images/esprionutrition.svg"
              alt="{{ 'general.title' | translate }}"
              width="200px"
            />
          </p>
          <p class="text-white description">
            {{ 'general.sub_headline' | translate }}
          </p>
        </div>
        <div class="col-md-3 gx-5 top-padding">
          <h3 class="text-white">{{ 'general.address' | translate }}</h3>
          <p class="text-white">
            seracom GmbH<br />
            Immenhofer Str. 23<br />
            70180 Stuttgart
          </p>
        </div>
        <div class="col-md-3 gx-5 top-padding">
          <h3 class="text-white">{{ 'general.contact_info' | translate }}</h3>
          <p class="text-white">
            +49 711 32095-333<br />
            <a href="mailto:{{ 'general.contact_email' | translate }}" target="_blank">{{
              'general.contact_email' | translate
            }}</a>
            <br />
            <a href="#" (click)="handleLegalNoticeClick($event)">{{
              'general.legal' | translate
            }}</a>
            <br />
            <a href="#" (click)="handlePrivacyPolicyClick($event)">{{
              'general.data_privacy' | translate
            }}</a>
          </p>
        </div>
      </div>
    </div>

    <div class="p-2">
      <p class="text-muted text-center m-0">Copyright © 2024 seracom GmbH</p>
    </div>
  </div>
</div>
