import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AsyncAction } from '../../../core/async-state/models/async-action.model';
import { handleErrors } from '../../../core/async-state/operators/handle-errors';
import { ModalsActions } from '../../../core/modals/actions/modals.actions';
import { ApiResponse, User, UserListItem } from '../../../shared/models';
import { UserDetailsResponse } from '../../../shared/models/responses/get-user-details-response.module';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { UserApiActions } from '../actions/user-api.actions';
import { UserApiService } from '../services/user-api.service';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '../../../core/auth/selectors/auth.selectors';
import getLoggedInUser = AuthSelectors.getLoggedInUser;
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {
  MonitoredInvitationsResponse
} from '../../../shared/models/responses/monitored-invitations-response';

@Injectable()
export class UserEffects {
  listUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserApiActions.listUsers),
        switchMap(() =>
          this.apiService.getUsers().pipe(
            handleErrors(() => UserApiActions.listUsersFail()),
            map((res: ApiResponse<UserListItem[]>) =>
              UserApiActions.listUsersSuccess({ userListItem: res.data })
            ),
            catchError((errAction: AsyncAction) => of(errAction))
          )
        )
      ),
    { dispatch: true }
  );

  getMonitoredUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserApiActions.getMonitoredUsers),
        switchMap((action) =>
          this.apiService.getMonitoredUsers(action.userId).pipe(
            handleErrors(() => UserApiActions.getMonitoredUsersFail()),
            map((res: ApiResponse<UserListItem[]>) =>
              UserApiActions.getMonitoredUsersSuccess({ users: res.data })
            ),
            catchError((errAction: AsyncAction) => of(errAction))
          )
        )
      ),
    { dispatch: true }
  );

  removeMonitoredUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.removeMonitoredUsers),
      switchMap((action) =>
        this.apiService
          .removeMonitoredUsers(action.userId, action.removeMonitoredUsersRequest)
          .pipe(
            handleErrors(() => UserApiActions.removeMonitoredUsersFail()),
            map(() =>
              UserApiActions.removeMonitoredUsersSuccess({
                removedMonitoredUserIds: action.removeMonitoredUsersRequest.monitoredUserIds,
              })
            ),
            catchError((errAction: AsyncAction) => of(errAction))
          )
      )
    )
  );

  removeMonitoredUsersSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserApiActions.removeMonitoredUsersSuccess),
        map(() => ModalsActions.closeConfirmRemoveMonitoredUsersModal())
      ),
    { dispatch: true }
  );

  public userDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserApiActions.getUserDetails),
        switchMap((action) => {
          return this.apiService.getUserDetails(action.userId).pipe(
            handleErrors(() => UserApiActions.getUserDetailsFail()),
            map((response: ApiResponse<UserDetailsResponse>) =>
              UserApiActions.getUserDetailsSuccess({ userDetailsResponse: response.data })
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          );
        })
      ),
    { dispatch: true }
  );

  generateUserCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.generateUserCode),
      switchMap((action) =>
        this.apiService.generateUserCode(action.userId).pipe(
          handleErrors(() => UserApiActions.generateUserCodeFail()),
          map(() => UserApiActions.generateUserCodeSuccess()),
          catchError((errAction: AsyncAction) => of(errAction))
        )
      )
    )
  );

  changeMonitoredUserFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.changeMonitoredUserFlow),
      switchMap((action) =>
        this.apiService
          .changeMonitoredUserFlow(action.userId, action.changeMonitoredUserFlowRequest)
          .pipe(
            handleErrors(() => UserApiActions.removeMonitoredUsersFail()),
            map(() => UserApiActions.changeMonitoredUserFlowSuccess({ userId: action.userId, changeMonitoredUserFlow: action.changeMonitoredUserFlowRequest })),
            catchError((errAction: AsyncAction) => of(errAction))
          )
      )
    )
  );

  changeMonitoredUserFlowSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.changeMonitoredUserFlowSuccess),
      switchMap((action) =>
        this.apiService.getMonitoredUsers(action.userId).pipe(
          handleErrors(() => UserApiActions.getMonitoredUsersFail()),
          map((res: ApiResponse<UserListItem[]>) =>
            UserApiActions.getMonitoredUsersSuccess({ users: res.data })
          ),
          catchError((errAction: AsyncAction) => of(errAction))
        )
      )
    )
  );

  confirmUserAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.confirmUserAccount),
      switchMap((action) =>
        this.apiService
          .confirmUserAccount(action.userId)
          .pipe(
            handleErrors(() => UserApiActions.confirmUserAccountFail()),
            map((result: ApiResponse<boolean>) => UserApiActions.confirmUserAccountSuccess({ confirmed: result.data })),
            catchError((errAction: AsyncAction) => of(errAction))
          )
      )
    ), { dispatch: true }
  );

  submitMonitoredUserInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.submitMonitoredUserInvitation),
      switchMap((action) =>
        this.apiService
          .submitMonitoredUserInvitation(action.email)
          .pipe(
            handleErrors(() => UserApiActions.submitMonitoredUserInvitationFail()),
            map(() => UserApiActions.submitMonitoredUserInvitationSuccess()),
            catchError((errAction: AsyncAction) => of(errAction))
          )
      )
    ), { dispatch: true }
  );

  submitMonitoredUserInvitationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.submitMonitoredUserInvitationSuccess),
      withLatestFrom(this.store.select(getLoggedInUser)),
      mergeMap(([_, user]: [any, User]) => [
        ModalsActions.closeInviteMonitoredUserModal(),
        UserApiActions.getMonitoredUsers({ userId: user.id })
      ]),
      tap(() => this.toastrService.success(this.translateService.instant('modal.invite_monitored_user.success_toast')))
    ), { dispatch: true }
  );

  submitMonitoredUserInvitationFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.submitMonitoredUserInvitationFail),
      tap(() => this.toastrService.error(this.translateService.instant('modal.invite_monitored_user.fail_toast_message'), this.translateService.instant('modal.invite_monitored_user.fail_toast')))
    ), { dispatch: false }
  );

  checkMonitoredUsersInvitations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.getMonitoredUsersInvitations),
      switchMap(() =>
        this.apiService
          .checkMonitoredUsersInvitations()
          .pipe(
            handleErrors(() => UserApiActions.getMonitoredUsersInvitationsFail()),
            map((response: ApiResponse<MonitoredInvitationsResponse>) => UserApiActions.getMonitoredUsersInvitationsSuccess({ monitoredUsersInvitations: response.data.invitations })),
            catchError((errAction: AsyncAction) => of(errAction))
          )
      )
    ), { dispatch: true }
  );

  getMonitoredUsersInvitationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.getMonitoredUsersInvitationsSuccess),
      mergeMap(({ monitoredUsersInvitations }) => {
        if (monitoredUsersInvitations.length) {
          return monitoredUsersInvitations.map(invitation => ModalsActions.openConfirmUserInvitationModal(invitation));
        } else {
          return [];
        }
        }
      )
    ), { dispatch: true }
  );

  confirmMonitoredUserInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.confirmMonitoredUserInvitation),
      switchMap((action) =>
        this.apiService
          .confirmMonitoredUserInvitation(action.monitoredUserId)
          .pipe(
            handleErrors(() => UserApiActions.confirmMonitoredUserInvitationFail()),
            map(() => UserApiActions.confirmMonitoredUserInvitationSuccess({ monitoredUserId: action.monitoredUserId })),
            catchError((errAction: AsyncAction) => of(errAction))
          )
      )
    ), { dispatch: true }
  );

  refreshMonitoredUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.confirmMonitoredUserInvitationSuccess),
      withLatestFrom(this.store.select(getLoggedInUser)),
      map(([_, user]: [any, User]) => UserApiActions.getMonitoredUsers({ userId: user.id })),
    ), { dispatch: true }
  );

  closeInvitationConfirmModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.confirmMonitoredUserInvitationSuccess, UserApiActions.declineMonitoredUserInvitationSuccess),
      map((action) => ModalsActions.closeConfirmUserInvitationModal({ id: action.monitoredUserId })),
    ), { dispatch: true }
  );

  declineMonitoredUserInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiActions.declineMonitoredUserInvitation),
      switchMap((action) =>
        this.apiService
          .declineMonitoredUserInvitation(action.monitoredUserId)
          .pipe(
            handleErrors(() => UserApiActions.declineMonitoredUserInvitationFail()),
            map(() => UserApiActions.declineMonitoredUserInvitationSuccess({ monitoredUserId: action.monitoredUserId })),
            catchError((errAction: AsyncAction) => of(errAction))
          )
      )
    ), { dispatch: true }
  );

  constructor(private actions$: Actions,
              private apiService: UserApiService,
              private store: Store,
              private toastrService: ToastrService,
              private translateService: TranslateService) {
  }
}
