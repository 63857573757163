import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '../../../app.definitions';
import { ApiResponse, UserListItem } from '../../../shared/models';
import { UpdateUserRequest } from '../../../shared/models/account/requests/update-user-request.model';
import { UserDetailsResponse } from '../../../shared/models/responses/get-user-details-response.module';
import { UpdateUserResponse } from '../../../shared/models/responses/update-user-response.module';
import { ChangeMonitoredUserFlowRequest } from '../../../shared/models/users/requests/change-monitored-user-flow-request.model';
import { RemoveMonitoredUsersRequest } from '../../../shared/models/users/requests/remove-monitored-user-request.model';
import { Observable } from 'rxjs';
import {
  MonitoredInvitationsResponse
} from '../../../shared/models/responses/monitored-invitations-response';

@Injectable()
export class UserApiService {
  constructor(@Inject(API_BASE_URL) private apiBaseUrl: string, private httpClient: HttpClient) {}

  public getUsers(): Observable<ApiResponse<UserListItem[]>> {
    return this.httpClient.get<ApiResponse<UserListItem[]>>(`${this.apiBaseUrl}/users`);
  }

  public getMonitoredUsers(userId: string) {
    return this.httpClient.get<ApiResponse<UserListItem[]>>(
      `${this.apiBaseUrl}/monitoring/${userId}/users`
    );
  }

  public removeMonitoredUsers(
    userId: string,
    request: RemoveMonitoredUsersRequest
  ): Observable<ApiResponse<{}>> {
    return this.httpClient.post<ApiResponse<{}>>(
      `${this.apiBaseUrl}/monitoring/${userId}/remove`,
      request
    );
  }

  public getUserDetails(userId: string): Observable<ApiResponse<UserDetailsResponse>> {
    return this.httpClient.get<ApiResponse<UserDetailsResponse>>(
      `${this.apiBaseUrl}/users/${userId}`
    );
  }

  public updateUser(
    userId: string,
    request: UpdateUserRequest
  ): Observable<ApiResponse<UpdateUserResponse>> {
    const url = `${this.apiBaseUrl}/users/${userId}/update`;
    return this.httpClient.put<ApiResponse<UpdateUserResponse>>(url, request);
  }

  public generateUserCode(userId: string): Observable<ApiResponse<{}>> {
    const url = `${this.apiBaseUrl}/users/${userId}/generate-code`;
    return this.httpClient.put<ApiResponse<{}>>(url, {});
  }

  public changeMonitoredUserFlow(
    userId: string,
    request: ChangeMonitoredUserFlowRequest
  ): Observable<ApiResponse<{}>> {
    return this.httpClient.post<ApiResponse<{}>>(
      `${this.apiBaseUrl}/users/${userId}/change-flow`,
      request
    );
  }

  confirmUserAccount(userId: string): Observable<ApiResponse<boolean>> {
    return this.httpClient.post<ApiResponse<boolean>>(
      `${ this.apiBaseUrl }/account/${ userId }/confirm`, {}
    );
  }

  submitMonitoredUserInvitation(email: any) {
    return this.httpClient.post<ApiResponse<boolean>>(
      `${ this.apiBaseUrl }/monitoring/me/invitation`, {
        email
      }
    );
  }

  checkMonitoredUsersInvitations(): Observable<ApiResponse<MonitoredInvitationsResponse>> {
    return this.httpClient.get<ApiResponse<MonitoredInvitationsResponse>>(
      `${ this.apiBaseUrl }/monitoring/me/invitation`
    );
  }

  confirmMonitoredUserInvitation(monitoredUserId: string) {
    return this.httpClient.post(
      `${ this.apiBaseUrl }/monitoring/me/invitation/${ monitoredUserId }`, {}
    );
  }

  declineMonitoredUserInvitation(monitoredUserId: string) {
    return this.httpClient.delete(
      `${ this.apiBaseUrl }/monitoring/me/invitation/${ monitoredUserId }`
    );
  }
}
