import { Component, Input } from '@angular/core';
import { SymptomHistoryData, SymptomType } from '../../../../shared/models';
import { fromInput } from '../../../../shared/utilities/observable-utils';
import { groupBy, snakeCase } from 'lodash';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { LineChartData } from '../monthly-multiline-chart/monthly-multiline-chart.component';

@Component({
  selector: 'app-symptoms-history-chart',
  templateUrl: './symptoms-history-chart.component.html',
  styleUrls: ['./symptoms-history-chart.component.scss'],
})
export class SymptomsHistoryChartComponent {
  @Input('data') public rawData?: SymptomHistoryData[] | null;
  @Input() public date?: Date | null;

  public readonly colorMap = {
    [SymptomType.DryMouth]: '#2d88c4',
    [SymptomType.Flatulence]: '#2dc4a3',
    [SymptomType.Diarrhea]: '#4b2dc4',
    [SymptomType.LackOfAppetite]: '#8d2dc4',
    [SymptomType.SoresInMouth]: '#c42dad',
    [SymptomType.Exhaustion]: '#c42d4b',
    [SymptomType.Constipation]: '#c46e2d',
    [SymptomType.Nausea]: '#c4ab2d',
    [SymptomType.AbdominalPain]: '#97c42d',
    [SymptomType.ChangesInTaste]: '#30c42d',
  };

  public rawData$ = fromInput<SymptomsHistoryChartComponent>(this)('rawData');
  public chartData$ = this.rawData$.pipe(
    map((data) => data ?? []),
    map((data) => groupBy(data, (item) => item.symptom)),
    map((data) => {
      const symptoms = Object.keys(data);
      const chartData: LineChartData[] = [];
      for (const symptom of symptoms) {
        chartData.push({
          color: this.colorMap[symptom],
          label: `general.symptoms.${snakeCase(symptom)}`,
          values: data[symptom].map((item) => ({
            x: moment(item.timestamp).valueOf(),
            y: item.value,
          })),
        });
      }
      return chartData;
    })
  );
}
