import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { DisclaimerModalComponent } from '../../../shared/components/disclaimer-modal/disclaimer-modal.component';
import { ConfirmRemoveMonitoredUsersModalComponent } from '../../../views/users-ui/components/confirm-remove-monitored-user-modal/confirm-remove-monitored-user-modal.component';
import { UserDetailsModalComponent } from '../../../views/users-ui/components/user-details-modal/user-details-modal.component';

import { ModalsActions } from '../actions/modals.actions';
import { DefaultModalConfig } from '../components/base-modal/default-modal-config.component';
import { BaseModalComponent } from '../components/base-modal/base-modal.component';
import { ConfirmChangeFlowModalComponent } from '../../../views/users-ui/components/user-details-modal/monitored-user-flow/confirm-change-flow-modal/confirm-change-flow-modal.component';
import { ChangeMonitoredUserFlowRequest } from '../../../shared/models/users/requests/change-monitored-user-flow-request.model';
import { InviteMonitoredUserComponent } from '../../../views/users-ui/components/users-list/invite-monitored-user/invite-monitored-user.component';
import { ConfirmUserInvitationModalComponent } from '../../../core/modals/components/confirm-user-invitation-modal/confirm-user-invitation-modal.component';
import { UserInvitation } from '../../../shared/models/users/monitored-invitation.model';
import { ConsentAgreementModalComponent } from '../../../shared/components/consent-agreement-modal/consent-agreement-modal.component';

@Injectable()
export class ModalsService {
  private userDetailsModal: OverlayRef | null = null;
  private confirmRemoveMonitoredUsersModal: OverlayRef | null = null;
  private confirmChangeFlowModal: OverlayRef | null = null;
  private disclaimerModal: OverlayRef | null = null;
  private consentAgreementModal: OverlayRef | null = null;
  private inviteMonitoredUserModal: OverlayRef | null = null;
  private confirmMonitoredUserModal: Map<string, OverlayRef> = new Map<string, OverlayRef>();

  constructor(private readonly overlay: Overlay) {}

  public openUserDetailsModal(userId: string): void {
    this.userDetailsModal = this.createDefaultModal({
      componentType: UserDetailsModalComponent,
      data: userId,
      closeAction: ModalsActions.closeUserDetailsModal(),
      specificPanelClass: ['monitored-user-details-modal-body'],
    });
  }

  public closeUserDetailsModal(): void {
    this.userDetailsModal?.dispose();
    this.userDetailsModal = null;
  }

  public openConfirmRemoveMonitoredUsersModal(users: string[]): void {
    this.confirmRemoveMonitoredUsersModal = this.createDefaultModal({
      componentType: ConfirmRemoveMonitoredUsersModalComponent,
      data: users,
      closeAction: ModalsActions.closeConfirmRemoveMonitoredUsersModal(),
      closeOnBackdropClick: false,
      specificPanelClass: ['remove-user-modal-body'],
    });
  }

  public openConfirmChangeFlowModal(request: ChangeMonitoredUserFlowRequest): void {
    this.confirmChangeFlowModal = this.createDefaultModal({
      componentType: ConfirmChangeFlowModalComponent,
      data: request,
      closeAction: ModalsActions.closeConfirmChangeFlowModal(),
      closeOnBackdropClick: false,
      specificPanelClass: ['remove-user-modal-body'],
    });
  }

  public closeConfirmRemoveMonitoredUsersModal(): void {
    this.confirmRemoveMonitoredUsersModal?.dispose();
    this.confirmRemoveMonitoredUsersModal = null;
  }

  public closeConfirmChangeFlowModal(): void {
    this.confirmChangeFlowModal?.dispose();
    this.confirmChangeFlowModal = null;
  }

  public openDisclaimerModal(): void {
    this.disclaimerModal = this.createDefaultModal({
      componentType: DisclaimerModalComponent,
      data: {},
      closeAction: ModalsActions.closeDisclaimerModal(),
    });
  }

  public closeDisclaimerModal(): void {
    this.disclaimerModal?.dispose();
    this.disclaimerModal = null;
  }

  public openConsentAgreementModal(): void {
    this.consentAgreementModal = this.createDefaultModal({
      componentType: ConsentAgreementModalComponent,
      data: {},
      closeAction: ModalsActions.closeConsentAgreementModal(),
    });
  }

  public closeConsentAgreementModal(): void {
    this.consentAgreementModal?.dispose();
    this.consentAgreementModal = null;
  }

  public openInviteMonitoredUserModal(): void {
    this.inviteMonitoredUserModal = this.createDefaultModal({
      componentType: InviteMonitoredUserComponent,
      data: {},
      closeAction: ModalsActions.closeInviteMonitoredUserModal(),
    });
  }

  public closeInviteMonitoredUserModal(): void {
    this.inviteMonitoredUserModal?.dispose();
    this.inviteMonitoredUserModal = null;
  }

  public openConfirmMonitoredUserInvitation(data: UserInvitation): void {
    this.confirmMonitoredUserModal.set(
      data.id,
      this.createDefaultModal({
        componentType: ConfirmUserInvitationModalComponent,
        data: data,
        closeAction: ModalsActions.closeConfirmUserInvitationModal({ id: data.id }),
      })
    );
  }

  public closeConfirmMonitoredUserInvitation(id: string): void {
    const modal = this.confirmMonitoredUserModal.get(id);
    modal?.dispose();
    this.confirmMonitoredUserModal.delete(id);
  }

  private createDefaultModal<TComponent extends BaseModalComponent<TData>, TData>({
    componentType,
    data,
    specificPanelClass,
    closeAction,
    closeOnBackdropClick,
  }: DefaultModalConfig<TComponent, TData>): OverlayRef {
    const overlayRef = this.overlay.create(this.getDefaultConfigForBaseModal(specificPanelClass));
    const portal = new ComponentPortal(componentType);
    const componentRef = overlayRef.attach(portal);
    componentRef.instance.data = data;
    componentRef.instance.closeAction = closeAction;
    if (closeOnBackdropClick) {
      this.registerDefaultBackdropClickBehaviour(overlayRef, componentRef);
    }
    return overlayRef;
  }

  private registerDefaultBackdropClickBehaviour<T>(
    overlay: OverlayRef,
    componentRef: ComponentRef<BaseModalComponent<T>>
  ): void {
    overlay.backdropClick().subscribe(() => {
      componentRef.instance.close();
    });
  }

  private getDefaultConfigForBaseModal(specificPanelClass?: string[]): OverlayConfig {
    return {
      disposeOnNavigation: true,
      hasBackdrop: true,
      panelClass: specificPanelClass
        ? ['default-modal-body', ...specificPanelClass]
        : 'default-modal-body',
      backdropClass: 'default-modal-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    };
  }
}
