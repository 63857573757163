import { Component } from '@angular/core';
import { AuthSelectors } from '../../../../../../core/auth/selectors/auth.selectors';
import { ModalsActions } from '../../../../../../core/modals/actions/modals.actions';
import { BaseModalComponent } from '../../../../../../core/modals/components/base-modal/base-modal.component';
import { UserApiActions } from '../../../../../../core/users/actions/user-api.actions';
import { User } from '../../../../../../shared/models';
import { ChangeMonitoredUserFlowRequest } from '../../../../../../shared/models/users/requests/change-monitored-user-flow-request.model';
import { combineLatest } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-change-flow-modal',
  templateUrl: './confirm-change-flow-modal.component.html',
  styleUrls: ['./confirm-change-flow-modal.component.scss'],
})
export class ConfirmChangeFlowModalComponent extends BaseModalComponent<ChangeMonitoredUserFlowRequest> {
  private loggedInUser$ = this.store.select(AuthSelectors.getLoggedInUser);

  public handleConfirmChangeFlow() {
    combineLatest([this.loggedInUser$, this.data$])
      .pipe(
        filter(([user, data]: [User, ChangeMonitoredUserFlowRequest]) => Boolean(user && data)),
        take(1),
        tap(([loggedInUser, data]) => {
          this.store.dispatch(
            UserApiActions.changeMonitoredUserFlow({
              userId: loggedInUser.id,
              changeMonitoredUserFlowRequest: data,
            })
          );
        })
      )
      .subscribe();

    this.handleCloseDialogClick();
  }

  public handleCloseDialogClick() {
    this.store.dispatch(ModalsActions.closeConfirmChangeFlowModal());
  }
}
