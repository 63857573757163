import { Component } from '@angular/core';
import { ModalsActions } from '../../../../core/modals/actions/modals.actions';
import { BaseModalComponent } from '../../../../core/modals/components/base-modal/base-modal.component';
import { UsersSelectors } from '../../../../core/users/selectors/users-selectors';

@Component({
  selector: 'app-user-details-modal',
  templateUrl: './user-details-modal.component.html',
  styleUrls: ['./user-details-modal.component.scss'],
})
export class UserDetailsModalComponent extends BaseModalComponent<string> {
  public selectedUser$ = this.store.select(UsersSelectors.getSelectedUser);

  public selectedTab = 0;

  public handleCloseDialogClick() {
    this.store.dispatch(ModalsActions.closeUserDetailsModal());
  }
}
