import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '../../../app.definitions';
import { SYMPTOMS_QUESTION_GROUPS } from '../../../shared/components/charts/symptons-yes-no-bar-chart/definitions/symptoms-question-groups';
import {
  ApiResponse,
  GeneralHealthHistoryData,
  SymptomHistoryData,
  WeightHistoryData,
} from '../../../shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class UserDataApiService {
  constructor(@Inject(API_BASE_URL) private apiBaseUrl: string, private httpClient: HttpClient) {}

  public getUserWeightHistoryData(
    userId: string,
    monitoredUserId: string,
    startDate?: Date,
    endDate?: Date
  ): Observable<ApiResponse<WeightHistoryData[]>> {
    const isoStartDate = startDate?.toISOString();
    const isoEndDate = endDate?.toISOString();

    let params = new HttpParams();
    if (isoStartDate) {
      params = params.set('startDate', isoStartDate);
    }
    if (isoEndDate) {
      params = params.set('endDate', isoEndDate);
    }

    return this.httpClient.get<ApiResponse<WeightHistoryData[]>>(
      `${this.apiBaseUrl}/monitoring/${userId}/${monitoredUserId}/history/weight-data`,
      { params }
    );
  }

  public getGeneralHealthHistoryData(
    userId: string,
    monitoredUserId: string,
    startDate?: Date,
    endDate?: Date
  ): Observable<ApiResponse<GeneralHealthHistoryData[]>> {
    const isoStartDate = startDate?.toISOString();
    const isoEndDate = endDate?.toISOString();

    let params = new HttpParams();
    if (isoStartDate) {
      params = params.set('startDate', isoStartDate);
    }
    if (isoEndDate) {
      params = params.set('endDate', isoEndDate);
    }

    return this.httpClient.get<ApiResponse<GeneralHealthHistoryData[]>>(
      `${this.apiBaseUrl}/monitoring/${userId}/${monitoredUserId}/history/general-health`,
      { params }
    );
  }

  public getSymptomsHistoryData(
    userId: string,
    monitoredUserId: string,
    startDate?: Date,
    endDate?: Date,
    submissionId?: string,
    questionGroup?: SYMPTOMS_QUESTION_GROUPS
  ): Observable<ApiResponse<SymptomHistoryData[]>> {
    const isoStartDate = startDate?.toISOString();
    const isoEndDate = endDate?.toISOString();

    let params = new HttpParams();
    if (isoStartDate) {
      params = params.set('startDate', isoStartDate);
    }
    if (isoEndDate) {
      params = params.set('endDate', isoEndDate);
    }
    if (submissionId) {
      params = params.set('submissionId', submissionId);
    }
    if (questionGroup) {
      params = params.set('questionGroup', questionGroup);
    }

    return this.httpClient.get<ApiResponse<SymptomHistoryData[]>>(
      `${this.apiBaseUrl}/monitoring/${userId}/${monitoredUserId}/history/symptoms`,
      { params }
    );
  }
}
