import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthActions } from '../../../../core/auth/actions/auth.actions';
import { BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePickerComponent implements OnInit, AfterViewInit, OnDestroy {
  userLanguage$ = new BehaviorSubject<string>(this.translateService.currentLang);
  isDropdownOpen = false;
  private langChangeSubscription: Subscription;

  @ViewChild(BsDropdownDirective, { static: false }) dropdown: BsDropdownDirective;

  constructor(public translateService: TranslateService, public store: Store) {}

  ngOnInit() {
    this.langChangeSubscription = this.translateService.onLangChange
      .pipe(tap((lang) => this.userLanguage$.next(lang.lang)))
      .subscribe();
  }

  ngAfterViewInit() {
    this.dropdown.onShown.subscribe(() => (this.isDropdownOpen = true));
    this.dropdown.onHidden.subscribe(() => (this.isDropdownOpen = false));
  }

  public selectLanguage(language: string) {
    this.store.dispatch(AuthActions.setUserLanguage({ language }));
    this.store.dispatch(AuthActions.updateUserLanguage({ language }));
    this.translateService.use(language);
  }

  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
  }
}
