import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '../../../../../core/auth/selectors/auth.selectors';
import { BaseAppState } from '../../../../../core/store/reducers';
import { SubmissionApiActions } from '../../../../../core/users/actions/user-submissions-api.actions';
import { UserDataSelectors } from '../../../../../core/users/selectors/user-data.selectors';
import { UsersSelectors } from '../../../../../core/users/selectors/users-selectors';
import { User } from '../../../../../shared/models';
import { combineLatest } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-monitored-user-submissions-list',
  templateUrl: './monitored-user-submissions-list.component.html',
  styleUrls: ['./monitored-user-submissions-list.component.scss'],
})
export class MonitoredUserSubmissionsListComponent implements OnInit {
  public selectedUserId$ = this.store.select(UsersSelectors.getSelectedUserId);
  public submissions$ = this.store.select(UserDataSelectors.getSubmissions);
  public submissionDetails$ = this.store.select(UserDataSelectors.getSubmissionDetails);
  public hasSubmissions$ = this.submissions$.pipe(
    map((submissions) => !!Object.keys(submissions).length)
  );
  private loggedInUser$ = this.store.select(AuthSelectors.getLoggedInUser);

  public isAnalysisView = false;
  public selectedSubmission = null;

  constructor(private store: Store<BaseAppState>) {}

  public ngOnInit(): void {
    this.loadMonitoredUserSubmissions();
  }

  public onSurveyClick(submission): void {
    this.selectedSubmission = submission;
    this.isAnalysisView = true;

    combineLatest([this.selectedUserId$, this.loggedInUser$]).pipe(
      take(1),
      filter(([_, loggedInUser]: [string, User]) => Boolean(loggedInUser)),
      tap(([selectedUserId, loggedInUser]) => {
        this.store.dispatch(SubmissionApiActions.getSubmissionDetails({
          userId: loggedInUser.id,
          monitoredUserId: selectedUserId,
          submissionId: submission.id
        }));
      }),
    ).subscribe();
  }

  public switchBackToListView(): void {
    this.isAnalysisView = false;
    this.selectedSubmission = null;
    this.store.dispatch(SubmissionApiActions.clearSubmissionDetails());
  }

  private loadMonitoredUserSubmissions() {
    combineLatest([this.selectedUserId$, this.loggedInUser$])
      .pipe(
        take(1),
        filter(([_, loggedInUser]: [string, User]) => Boolean(loggedInUser)),
        tap(([selectedUserId, loggedInUser]) => {
          this.store.dispatch(
            SubmissionApiActions.getSubmissions({
              userId: loggedInUser.id,
              monitoredUserId: selectedUserId,
            })
          );
        })
      )
      .subscribe();
  }
}
