import { Pipe, PipeTransform } from '@angular/core';
import { CancerTherapyType } from '../../shared/models';

@Pipe({
  name: 'therapyType',
})
export class TherapyTypePipe implements PipeTransform {
  private readonly therapyTypesList = {
    [CancerTherapyType.Unknown]: 'general.therapy_type.unknown',
    [CancerTherapyType.Other]: 'general.therapy_type.other',
    [CancerTherapyType.Immunobody]: 'general.therapy_type.immunobody',
    [CancerTherapyType.Antibody]: 'general.therapy_type.antibody',
    [CancerTherapyType.Radiation]: 'general.therapy_type.radiation',
    [CancerTherapyType.Hormone]: 'general.therapy_type.hormone',
    [CancerTherapyType.Chemo]: 'general.therapy_type.chemo',
    [CancerTherapyType.Surgery]: 'general.therapy_type.surgery',
  };

  transform(value: CancerTherapyType): string {
    const transformedValue = this.therapyTypesList[value];
    if (transformedValue === undefined) {
      throw new Error(`Invalid Cancer Therapy Type value: ${value}`);
    }
    return transformedValue;
  }
}
