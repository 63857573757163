import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Chart, ChartConfiguration, Point } from 'chart.js';
import * as moment from 'moment';
import { takeUntil, tap } from 'rxjs/operators';
import { groupBy } from 'lodash';
import { combineLatest } from 'rxjs';
import { SymptomData } from '../../../../shared/models/submissions/symptom-data.model';
import { fromInput } from '../../../../shared/utilities/observable-utils';
import { ObservableComponent } from 'ngx-esprio-shared';

@Component({
  selector: 'app-symptons-yes-no-bar-chart',
  templateUrl: './symptons-yes-no-bar-chart.component.html',
  styleUrls: ['./symptons-yes-no-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymptonsYesNoBarChartComponent extends ObservableComponent implements OnInit {
  @Input() data: SymptomData[];
  @Input() date?: Date | null = null;

  date$ = fromInput<SymptonsYesNoBarChartComponent>(this)('date');
  data$ = fromInput<SymptonsYesNoBarChartComponent>(this)('data');

  private yesAnswers: Point[] = [];
  private noAnswers: Point[] = [];
  private chart: Chart;

  ngOnInit(): void {
    this.initChart();
  }

  private initChart() {
    combineLatest([this.data$, this.date$])
      .pipe(
        takeUntil(this.ngDestroy$),
        tap(([data, date]: [SymptomData[], Date]) => {
          this.prepareData(data);
          if (!this.chart) {
            this.createChart(date);
          } else {
            this.updateChart(date);
          }
        })
      )
      .subscribe();
  }

  createChart(date: Date) {
    this.chart = new Chart('symptonsYesNoBarChart', this.getChartConfig(date));
  }

  private updateChart(date: Date) {
    const chartConfiguration = this.getChartConfig(date);
    this.chart.data.datasets = chartConfiguration.data.datasets;
    this.chart.update();
  }

  private prepareData(data: SymptomData[]): void {
    const dataByDate = groupBy(data, 'timestamp');

    this.yesAnswers = [];
    this.noAnswers = [];

    for (const key in dataByDate) {
      this.yesAnswers.push({
        x: moment(key).valueOf(),
        y: dataByDate[key].filter((symptomData) => symptomData.value === 2).length,
      });

      this.noAnswers.push({
        x: moment(key).valueOf(),
        y: dataByDate[key].filter((symptomData) => symptomData.value === 0).length,
      });
    }
  }

  private getChartConfig(date: Date): ChartConfiguration {
    return {
      type: 'bar',
      data: {
        datasets: [
          {
            label: 'Ja',
            data: this.yesAnswers,
            backgroundColor: 'rgba(48, 188, 237, 1)',
            borderColor: 'rgba(48, 188, 237, 1)',
            hoverBackgroundColor: 'rgba(48, 188, 237, 1)',
            hoverBorderColor: 'rgba(48, 188, 237, 1)',
            borderWidth: 1,
          },
          {
            label: 'Nein',
            data: this.noAnswers,
            backgroundColor: 'rgba(52, 220, 119, 0.7)',
            borderColor: 'rgba(52, 220, 119, 0.7)',
            hoverBackgroundColor: 'rgba(52, 220, 119, 0.7)',
            hoverBorderColor: 'rgba(52, 220, 119, 0.7)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'DD',
              },
            },
            min: moment(date).startOf('month').valueOf(),
            max: moment(date).endOf('month').valueOf(),
          },
          y: {
            stacked: true,
            suggestedMin: 0,
            suggestedMax: 10,
          },
        },
      },
    };
  }
}
