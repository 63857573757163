import { Component, Input } from '@angular/core';
import { WeightHistoryData } from '../../../../shared/models';
import { fromInput } from '../../../../shared/utilities/observable-utils';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { LineChartData } from '../monthly-multiline-chart/monthly-multiline-chart.component';

@Component({
  selector: 'app-weight-chart',
  templateUrl: './weight-chart.component.html',
  styleUrls: ['./weight-chart.component.scss'],
})
export class WeightChartComponent {
  public readonly color = '#30BCED';

  @Input() public data?: WeightHistoryData[] | null;
  @Input() public date?: Date | null;

  public rangeYAxis: { min: number; max: number };

  public data$ = fromInput<WeightChartComponent>(this)('data');
  public chartData$ = this.data$.pipe(
    map((data) => {
      if (!data?.length) {
        return [];
      }

      const weights = [...data].map((x) => x.weight);
      this.rangeYAxis = { min: Math.min(...weights) - 5, max: Math.max(...weights) + 5 };

      return [
        {
          color: this.color,
          values: data.map((item) => ({ x: moment(item.timestamp).valueOf(), y: item.weight })),
        } as LineChartData,
      ];
    })
  );
}
