import { props } from '@ngrx/store';
import { createAsyncAction } from '../../../core/async-state/utils/async-state-action.utils';
import { GetSubmissionsResponse } from '../../../shared/models/responses/get-submissions-response.modal';
import { SubmissionListItem } from '../../../shared/models/submissions/submission-list-item.model';

export namespace SubmissionApiActions {
  enum SubmissionApiActionTypes {
    GetSubmissions = '[Submission API] Get submissions',
    GetSubmissionsSuccess = '[Submission API] Get submissions success',
    GetSubmissionsFail = '[Submission API] Get submissions fail',
    GetSubmissionDetails = '[Submission API] Get submission Details',
    GetSubmissionDetailsSuccess = '[Submission API] Get submission Details success',
    GetSubmissionDetailsFail = '[Submission API] Get submission Details fail',
    ClearSubmissionDetails =  '[Submissions] Clear Submission Details',
  }

  export const getSubmissions = createAsyncAction(
    SubmissionApiActionTypes.GetSubmissions,
    {
      asyncKey: SubmissionApiActionTypes.GetSubmissions,
      asyncState: 'start',
    },
    props<{ userId: string, monitoredUserId: string }>(),
  );

  export const getSubmissionsSuccess = createAsyncAction(
    SubmissionApiActionTypes.GetSubmissionsSuccess,
    {
      asyncKey: SubmissionApiActionTypes.GetSubmissions,
      asyncState: 'success',
    },
    props<{ payload: GetSubmissionsResponse }>()
  );

  export const getSubmissionsFail = createAsyncAction(
    SubmissionApiActionTypes.GetSubmissionsFail,
    {
      asyncKey: SubmissionApiActionTypes.GetSubmissions,
      asyncState: 'fail',
    },
  );

  export const getSubmissionDetails = createAsyncAction(
    SubmissionApiActionTypes.GetSubmissionDetails,
    {
      asyncKey: SubmissionApiActionTypes.GetSubmissionDetails,
      asyncState: 'start',
    },
    props<{ userId: string, monitoredUserId: string, submissionId: string }>(),
  );

  export const getSubmissionDetailsSuccess = createAsyncAction(
    SubmissionApiActionTypes.GetSubmissionDetailsSuccess,
    {
      asyncKey: SubmissionApiActionTypes.GetSubmissionDetails,
      asyncState: 'success',
    },
    props<{ payload: SubmissionListItem }>()
  );

  export const getSubmissionDetailsFail = createAsyncAction(
    SubmissionApiActionTypes.GetSubmissionDetailsFail,
    {
      asyncKey: SubmissionApiActionTypes.GetSubmissionDetails,
      asyncState: 'fail',
    },
  );

  export const clearSubmissionDetails = createAsyncAction(
    SubmissionApiActionTypes.ClearSubmissionDetails,
    {
      asyncKey: SubmissionApiActionTypes.ClearSubmissionDetails,
      asyncState: 'start',
    }
  );
}
