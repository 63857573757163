import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { AsyncStateOverlayModule } from '../../core/async-state/async-state.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomTranslatorModule } from '../../shared/i18n/custom-translate.module';
import { ModalsEffects } from './effects/modals.effects';
import { ModalsService } from './services/modals.service';
import {
  ConfirmUserInvitationModalComponent
} from '../../core/modals/components/confirm-user-invitation-modal/confirm-user-invitation-modal.component';

@NgModule({
  declarations: [
    ConfirmUserInvitationModalComponent
  ],
  imports: [
    CommonModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncStateOverlayModule,
    CustomTranslatorModule,
    EffectsModule.forFeature([ModalsEffects]),
  ],
  providers: [ModalsService],
})
export class ModalsModule {}
