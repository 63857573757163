import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserFlow } from '../../shared/models';

@Pipe({
  name: 'flow',
})
export class FlowPipe implements PipeTransform {
  public constructor(private translate: TranslateService) {}

  private readonly flowList = {
    [UserFlow.Green]: this.translate.instant('general.change_flow.green_flow_info'),
    [UserFlow.Yellow]: this.translate.instant('general.change_flow.yellow_flow_info'),
    [UserFlow.Red]: this.translate.instant('general.change_flow.red_flow_info'),
  };

  transform(flow: UserFlow): string {
    const transformedValue = this.flowList[flow];
    if (transformedValue === undefined) {
      throw new Error(`Invalid User Flow value: ${flow}`);
    }
    return transformedValue;
  }
}
