import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '../../../app.definitions';
import {
  ApiResponse,
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  RenewCodeResponse,
  RequestPasswordResetRequest,
  ResetPasswordRequest,
  UpdatePasswordRequest, User,
} from '../../../shared/models';
import { Observable } from 'rxjs';
import { SignUpRequest } from '../../../shared/models/account/requests/sign-up-request.model';
import {
  SignUpConfirmRequest
} from '../../../shared/models/account/requests/sign-up-confirm-request.model';

@Injectable()
export class AuthService {
  constructor(@Inject(API_BASE_URL) private apiBaseUrl: string, private httpClient: HttpClient) {}

  login(email: string, password: string): Observable<ApiResponse<LoginResponse>> {
    const url = `${this.apiBaseUrl}/account/monitoring/signin`;
    const body: LoginRequest = {
      email,
      password,
    };

    return this.httpClient.post<ApiResponse<LoginResponse>>(url, body);
  }

  logout(request: LogoutRequest): Observable<ApiResponse<{}>> {
    const url = `${this.apiBaseUrl}/account/signout`;

    return this.httpClient.post<ApiResponse<{}>>(url, request);
  }

  updatePassword(request: UpdatePasswordRequest): Observable<ApiResponse<{}>> {
    const url = `${this.apiBaseUrl}/account/password/change`;
    return this.httpClient.post<ApiResponse<{}>>(url, request);
  }

  requestPasswordReset(request: RequestPasswordResetRequest): Observable<ApiResponse<{}>> {
    const url = `${this.apiBaseUrl}/account/password/reset`;
    return this.httpClient.post<ApiResponse<{}>>(url, request);
  }

  resetPassword(request: ResetPasswordRequest): Observable<ApiResponse<{}>> {
    const url = `${this.apiBaseUrl}/account/password/reset/confirm`;
    return this.httpClient.post<ApiResponse<{}>>(url, request);
  }

  renewCode(): Observable<ApiResponse<RenewCodeResponse>> {
    const url = `${this.apiBaseUrl}/account/code/renew`;
    return this.httpClient.post<ApiResponse<RenewCodeResponse>>(url, {});
  }

  confirmTermsOfService(): Observable<ApiResponse<{}>> {
    const url = `${this.apiBaseUrl}/account/me/confirm-tos`;
    return this.httpClient.post<ApiResponse<{}>>(url, {});
  }

  updateUserLanguage(language: string) {
    const url = `${ this.apiBaseUrl }/users/me/language`;
    return this.httpClient.put<ApiResponse<{ user: User }>>(url, { language });
  }

  public signUp(signUpRequest: SignUpRequest): Observable<ApiResponse<Record<string, never>>> {
    const url = `${this.apiBaseUrl}/monitoring/account/signup`;
    return this.httpClient.post<ApiResponse<Record<string, never>>>(url, signUpRequest);
  }

  public confirmSignUp(userId: string, code: string): Observable<ApiResponse<LoginResponse>> {
    const url = `${this.apiBaseUrl}/monitoring/account/signup/confirm`;
    const body: SignUpConfirmRequest = {
      userId,
      code,
    };
    return this.httpClient.post<ApiResponse<LoginResponse>>(url, body);
  }

  resendConfirmationLink(email: string): Observable<ApiResponse<boolean>> {
    const url = `${this.apiBaseUrl}/account/resend-confirmation`;
    return this.httpClient.post<ApiResponse<boolean>>(url, {
      email,
    });
  }
}
