import { createAction, props } from '@ngrx/store';
import { createAsyncAction } from '../../../core/async-state/utils/async-state-action.utils';
import { SYMPTOMS_QUESTION_GROUPS } from '../../../shared/components/charts/symptons-yes-no-bar-chart/definitions/symptoms-question-groups';
import { GeneralHealthHistoryData, SymptomHistoryData, WeightHistoryData } from '../../../shared/models';

export namespace UserDataApiActions {
  enum UserDataApiActionTypes {
    GetUserWeightHistoryData = '[User Data API] Get user weight history data',
    GetUserWeightHistoryDataSuccess = '[User Data API] Get user weight history data success',
    GetUserWeightHistoryDataFail = '[User Data API] Get user weight history data fail',

    GetUserSymptomsHistoryData = '[User Data API] Get user symptoms history data',
    GetUserSymptomsHistoryDataSuccess = '[User Data API] Get user symptoms history data success',
    GetUserSymptomsHistoryDataFail = '[User Data API] Get user symptoms history data fail',

    GetUserGeneralHealthHistoryData = '[User Data API] Get user general health history data',
    GetUserGeneralHealthHistoryDataSuccess = '[User Data API] Get user general health history data success',
    GetUserGeneralHealthHistoryDataFail = '[User Data API] Get user general health history data fail',

    PurgeUserData = '[User Data] Purge user data',
  }

  export const getUserWeightHistoryData = createAsyncAction(
    UserDataApiActionTypes.GetUserWeightHistoryData,
    {
      asyncKey: UserDataApiActionTypes.GetUserWeightHistoryData,
      asyncState: 'start',
    },
    props<{ userId: string; monitoredUserId: string; startDate?: Date; endDate?: Date }>()
  );

  export const getUserWeightHistoryDataSuccess = createAsyncAction(
    UserDataApiActionTypes.GetUserWeightHistoryDataSuccess,
    {
      asyncKey: UserDataApiActionTypes.GetUserWeightHistoryData,
      asyncState: 'success',
    },
    props<{ payload: WeightHistoryData[] }>()
  );

  export const getUserWeightHistoryDataFail = createAsyncAction(
    UserDataApiActionTypes.GetUserWeightHistoryDataFail,
    {
      asyncKey: UserDataApiActionTypes.GetUserWeightHistoryData,
      asyncState: 'fail',
    }
  );

  export const getUserSymptomsHistoryData = createAsyncAction(
    UserDataApiActionTypes.GetUserSymptomsHistoryData,
    {
      asyncKey: UserDataApiActionTypes.GetUserSymptomsHistoryData,
      asyncState: 'start',
    },
    props<{
      userId: string;
      monitoredUserId: string;
      startDate?: Date;
      endDate?: Date;
      submissionId?: string;
      questionGroup?: SYMPTOMS_QUESTION_GROUPS;
    }>()
  );

  export const getUserSymptomsHistoryDataSuccess = createAsyncAction(
    UserDataApiActionTypes.GetUserSymptomsHistoryDataSuccess,
    {
      asyncKey: UserDataApiActionTypes.GetUserSymptomsHistoryData,
      asyncState: 'success',
    },
    props<{ payload: SymptomHistoryData[]; questionGroup?: SYMPTOMS_QUESTION_GROUPS }>()
  );

  export const getUserSymptomsHistoryDataFail = createAsyncAction(
    UserDataApiActionTypes.GetUserSymptomsHistoryDataFail,
    {
      asyncKey: UserDataApiActionTypes.GetUserSymptomsHistoryData,
      asyncState: 'fail',
    }
  );

  export const getUserGeneralHealthHistoryData = createAsyncAction(
    UserDataApiActionTypes.GetUserGeneralHealthHistoryData,
    {
      asyncKey: UserDataApiActionTypes.GetUserGeneralHealthHistoryData,
      asyncState: 'start',
    },
    props<{ userId: string; monitoredUserId: string; startDate?: Date; endDate?: Date }>()
  );

  export const getUserGeneralHealthHistoryDataSuccess = createAsyncAction(
    UserDataApiActionTypes.GetUserGeneralHealthHistoryDataSuccess,
    {
      asyncKey: UserDataApiActionTypes.GetUserGeneralHealthHistoryData,
      asyncState: 'success',
    },
    props<{ payload: GeneralHealthHistoryData[] }>()
  );

  export const getUserGeneralHealthHistoryDataFail = createAsyncAction(
    UserDataApiActionTypes.GetUserGeneralHealthHistoryDataFail,
    {
      asyncKey: UserDataApiActionTypes.GetUserGeneralHealthHistoryData,
      asyncState: 'fail',
    }
  );

  export const purgeUserData = createAction(UserDataApiActions.purgeUserData);
}
