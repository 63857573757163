import { Component } from '@angular/core';
import { AuthSelectors } from '../../../../core/auth/selectors/auth.selectors';
import { ModalsActions } from '../../../../core/modals/actions/modals.actions';
import { BaseModalComponent } from '../../../../core/modals/components/base-modal/base-modal.component';
import { UserApiActions } from '../../../../core/users/actions/user-api.actions';
import { User } from '../../../../shared/models';
import { RemoveMonitoredUsersRequest } from '../../../../shared/models/users/requests/remove-monitored-user-request.model';
import { combineLatest } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-remove-monitored-user-modal',
  templateUrl: './confirm-remove-monitored-user-modal.component.html',
  styleUrls: ['./confirm-remove-monitored-user-modal.component.scss'],
})
export class ConfirmRemoveMonitoredUsersModalComponent extends BaseModalComponent<string[]> {
  private loggedInUser$ = this.store.select(AuthSelectors.getLoggedInUser);

  public handleRemoveMonitoredUserClick() {
    combineLatest([this.loggedInUser$, this.data$])
      .pipe(
        take(1),
        tap(([loggedInUser, selectedUserIds]: [User, string[]]) => {
          const request: RemoveMonitoredUsersRequest = {
            monitoredUserIds: selectedUserIds,
          };
          this.store.dispatch(
            UserApiActions.removeMonitoredUsers({
              userId: loggedInUser.id,
              removeMonitoredUsersRequest: request,
            })
          );
        })
      )
      .subscribe();
  }

  public handleCloseDialogClick() {
    this.store.dispatch(ModalsActions.closeConfirmRemoveMonitoredUsersModal());
  }
}
