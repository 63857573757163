import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgChartsModule } from 'ng2-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { SymptonsYesNoBarChartComponent } from './components/charts/symptons-yes-no-bar-chart/symptons-yes-no-bar-chart.component';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { DisclaimerModalComponent } from './components/disclaimer-modal/disclaimer-modal.component';
import { GeneralHealthChartComponent } from '../shared/components/charts/general-health-chart/general-health-chart.component';
import { MonthlyMultilineChartComponent } from '../shared/components/charts/monthly-multiline-chart/monthly-multiline-chart.component';
import { SlimeBackgroundComponent } from './components/slime-background/slime-background.component';
import { SymptomsHistoryChartComponent } from '../shared/components/charts/symptoms-history-chart/symptoms-history-chart.component';
import { WeightChartComponent } from '../shared/components/charts/weight-chart/weight-chart.component';
import { CustomTranslatorModule } from './i18n/custom-translate.module';
import { AnswerDisplayPipe } from './pipes/answer-display.pipe';
import { CancerTypePipe } from './pipes/cancer-type.pipe';
import { CodeFormatPipe } from './pipes/code-formate.pipe';
import { ColorPipe } from './pipes/color.pipe';
import { FlowPipe } from './pipes/flow.pipe';
import { GenderPipe } from './pipes/genders.pipe';
import { ProfessionTypePipe } from './pipes/profession-types.pipe';
import { RolesPipe } from './pipes/roles.pipe';
import { SortByPropertyPipe } from './pipes/sort-by-property.pipe';
import { SortUserPipe } from './pipes/sortUser.pipe';
import { TherapyTypePipe } from './pipes/therapy-type.pipe';
import { ConsentAgreementModalComponent } from './components/consent-agreement-modal/consent-agreement-modal.component';

const SHARED_COMPONENTS = [
  CustomTableComponent,
  GeneralHealthChartComponent,
  MonthlyMultilineChartComponent,
  SymptomsHistoryChartComponent,
  WeightChartComponent,
  SlimeBackgroundComponent,
  DisclaimerModalComponent,
  SymptonsYesNoBarChartComponent,
  ConsentAgreementModalComponent,
];
const SHARED_PIPES = [
  SortByPropertyPipe,
  CancerTypePipe,
  TherapyTypePipe,
  GenderPipe,
  RolesPipe,
  ProfessionTypePipe,
  SortUserPipe,
  CodeFormatPipe,
  FlowPipe,
  ColorPipe,
  AnswerDisplayPipe,
];

@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    CustomTranslatorModule,
    NgChartsModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  declarations: [...SHARED_COMPONENTS, ...SHARED_PIPES],
  exports: [...SHARED_COMPONENTS, ...SHARED_PIPES],
})
export class SharedModule {}
