import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseAppState } from '../../../core/store/reducers';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AuthActions } from '../actions/auth.actions';

import { AuthSelectors } from '../selectors/auth.selectors';

@Injectable()
export class AuthTokenInvalidInterceptor implements HttpInterceptor {
  constructor(private store: Store<BaseAppState>) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      // eslint:disable-next-line: deprecation
      tap(undefined, (err: unknown) => this.handleSessionExpired(err))
    );
  }

  private handleSessionExpired(err: unknown) {
    this.store
      .pipe(select(AuthSelectors.getToken))
      .pipe(take(1))
      .subscribe((token) => {
        if (token && err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.store.dispatch(AuthActions.sessionExpired());
          }
        }
      });
  }
}
